body {
     margin: 0px;
     font-family: var(--font-family) !important;
}
h1, h2, h3, h4, h5, h6 {
     color: var(--text-color);
     font-weight: 500;
     margin: 5px 0px 20px 0px !important;
}
h1 {
     font-size: 1.7rem;
}
h2 {
     font-size: 1.6rem;
}
h3 {
     font-size: 1.4rem;
}
h4 {
     font-size: 1.3rem;
}
h5 {
     font-size: 1.2rem;
}
h6 {
     font-size: 1.1rem;
}
img {
     max-width: 100% !important;
}
p {
     display: block;
     margin-top: 14px;
     margin-bottom: 14px;
}
//Transition start
.trans {
     transition: 0.7s;
}
//Transition ends
//Links starts
.links {
     text-decoration: none;
     cursor: pointer;
}
.links:hover {
     text-decoration: underline;
}
//Links ends
//Cursor Pointer starts
.curPointer {
     cursor: pointer;
}
//Cursor Pointer ends
//Form mandatory indicator starts
.mandatory {
     color: #D80914;
}
//Form mandatory indicator starts
//Success, Error, Info, prime icon Colors starts
.clr-success {
     color: var(--green-700);
}
.clr-error, .clr-danger {
     color: var(--red-700);
}
.info-bg {
     background-color: #A0CEF8;
}
.transparent-bg {
     background-color: transparent;
}
//Password show hide icon starts
.eye-icon {
     position: absolute;
     top: 60%;
     right: 4%;
     text-align: right;
     cursor: pointer;
     img {
          width: 20px;
     }
}
//Password show hide icon ends
//Secondary button starts
.secondary-button-large {
     cursor: pointer;
     color: var(--always-white);
     background-color: var(--secondary-color);
     height: 50px;
     border-radius: 4px;
     padding: 8px 16px;
     border: 1px solid var(--always-white);
     font-family: var(--font-family);
     font-weight: 500;
     font-size: 20px;
     line-height: 30px;
     text-align: center;
     transition: 0.7s;
}
.secondary-button-large:hover {
     color: var(--secondary-color);
     border: 1px solid var(--secondary-color);
     background-color: var(--always-white);
}
.secondary-button-large:disabled {
     pointer-events: none;
     opacity: 0.5;
}
//Secondary button ends
//Reverse button starts
.reverse-button {
     cursor: pointer;
     color: var(--dataview-title);
     background-color: var(--always-white);
     border-radius: 4px;
     padding: 8px 16px;
     border: 1px solid var(--dataview-title);
     font-weight: 500;
     text-align: center;
     transition: 0.7s;
     font-family: var(--font-family);
     font-feature-settings: var(--font-feature-settings, normal);
     font-size: 1rem;
}
.reverse-button:hover {
     color: var(--always-white);
     border: 1px solid var(--dataview-title);
     background-color: var(--dataview-title);
}
.reverse-button:disabled {
     pointer-events: none;
     opacity: 0.5;
}
//Reverse button ends
//Side bar close button starts
.closeIcon {
     display: block;
     @media (min-width: 992px) {
          display: none;
     }
}
//Side bar close button ends
//Disable click on page on Is loading progress bar is showing starts
.disableClickOnPage {
     pointer-events: none;
}
.enableClickOnPage {
     pointer-events: all;
}
//Primeng component related css starts
button:focus {
     box-shadow:none;
     -webkit-box-shadow:none;
     -moz-box-shadow:none;
}
.card {
     background: var(--surface-card);
     border: 1px solid var(--surface-border);
     padding: 2rem;
     margin-bottom: 2rem;
     box-shadow: var(--card-shadow);
     border-radius: 12px
}
.p-card-subtitle {
     font-size: 1.2rem !important;
     font-weight: 500 !important;
     color: var(--text-color);
}
.progressBar {
     position: fixed;
     width: 100%;
     z-index: 9999;
}
.p-dialog .p-dialog-content {
     padding: 0px !important
}
.customToast {
     width: max-content;
     max-width: 90%;
     &.p-toast {
          .p-toast-message {
               margin: 0px;
               border-radius: 0px;
               &.p-toast-message-error {
                    border: none;
                    background: none;
               }
               &.p-toast-message-success {
                    border: none;
                    background: none;
               }
               &.p-toast-message-info {
                    border: none;
                    background: none;
               }
               .toastOuter {
                    padding: 20px;
                    color: #33425B;
                    font-weight: 500;
                    font-size: 16px;
                    border-radius: 4px;
               }
               .errotToast {
                    background-color: #F8A0A5;
               }
               .successToast {
                    background-color: #C4F8A0;
               }
               .infoToast {
                    background-color: #A0CEF8;
               }
               .message {
                    margin-right: 20px;
               }
               .icon {
                    background: #ffffff;
                    padding: 5px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
               }
               .closeBtn {
                    display: flex;
                    padding-top: 4px;
                    cursor: pointer;
               }
          }
     }
}
.p-paginator {
     justify-content: right;
}
.table-edit-text {
     font-family: var(--font-family);
     font-feature-settings: var(--font-feature-settings, normal);
     font-size: 1rem;
     color: var(--text-color);
     border: 1px solid rgba(0,0,0,0);
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     appearance: none;
     border-radius: 6px;
     max-height: 20px;
}
.table-action-icon {
     cursor: pointer;
     padding: 5px;
}
.table-expansion-container {
     box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
     border-radius: 4px;
}
.p-confirm-dialog-reject {
     cursor: pointer;
     color: var(--dataview-title);
     background-color: var(--always-white);
     border-radius: 4px;
     padding: 8px 16px;
     border: 1px solid var(--dataview-title);
     font-weight: 500;
     text-align: center;
     transition: 0.7s;
     font-family: var(--font-family);
     font-feature-settings: var(--font-feature-settings, normal);
     font-size: 1rem;
}
.p-confirm-dialog-reject:hover {
     color: var(--always-white);
     border: 1px solid var(--dataview-title);
     background-color: var(--dataview-title);
}
.p-confirm-dialog-reject:disabled {
     pointer-events: none;
     opacity: 0.5;
}
.p-dialog-content {
     overflow-y: visible;
}
.p-confirm-popup-content {
     padding-bottom: 0px;
}
.p-sidebar-left {
     width: 21.7rem;
}
.dashboardSub .p-card-body {
     padding-top: 0px !important;
     padding-bottom: 0px !important;
}
//Primeng component related css ends